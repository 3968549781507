export const eventImg = {
  //tech
  paper: require("../../assets/images/vpraAssets/social_media.jpeg"),
  project: require("../../assets/images/aiworkplace.jpg"),
  // story: require("../../assets/images/story.jpeg"),
  iot: require("../../assets/images/vpraAssets/iotHack.jpeg"),
  entrepreneur: require("../../assets/images/vpraAssets/enterprenuer.jpeg"),
  line: require("../../assets/images/vpraAssets/line.jpeg"),
  maze: require("../../assets/images/vpraAssets/maze.png"),
  robo: require("../../assets/images/vpraAssets/robo.jpeg"),
  circuit: require("../../assets/images/vpraAssets/circuit.jpeg"),
  techitout: require("../../assets/images/vpraAssets/iotHack.jpeg"),

  //nontech
  quiz: require("../../assets/images/vpraAssets/quiz.jpeg"),
  theme: require("../../assets/images/vpraAssets/camera.jpeg"),
  bridge: require("../../assets/images/vpraAssets/bridge.jpeg"),
  cube: require("../../assets/images/vpraAssets/cube.jpeg"),
  puzzle: require("../../assets/images/vpraAssets/puzzle.jpeg"),
  cid: require("../../assets/images/vpraAssets/mock.jpeg"),
  connections: require("../../assets/images/vpraAssets/connections.jpeg"),
  newspaper: require("../../assets/images/vpraAssets/newspaper.jpeg"),
  speaks: require("../../assets/images/vpraAssets/speaks.jpeg"),
  shoot: require("../../assets/images/vpraAssets/shoot.jpeg"),

  //workshop
  printing: require("../../assets/images/vpraAssets/Workshops/3d.jpeg"),
  fusion: require("../../assets/images/vpraAssets/Workshops/Fusion.png"),
  exposure: require("../../assets/images/vpraAssets/Workshops/exposure.png"),
  story: require("../../assets/images/story1.jpeg"),
  web: require("../../assets/images/vpraAssets/Workshops/web.png"),
  matlab: require("../../assets/images/vpraAssets/Workshops/matlab.png"),
  ethical: require("../../assets/images/vpraAssets/Workshops/ethical.png"),
  arvr: require("../../assets/images/vpraAssets/Workshops/arvr.png"),
  mobile: require("../../assets/images/vpraAssets/Workshops/mobile.png"),
  cyber: require("../../assets/images/vpraAssets/Workshops/cyber.png"),
  shading: require("../../assets/images/vpraAssets/shading.jpg"),
  drone: require("../../assets/images/vpraAssets/drone.jpg"),
  sql: require("../../assets/images/vpraAssets/sql.jpg"),
  fashion: require("../../assets/images/vpraAssets/fashion.jpg"),
  vr: require("../../assets/images/vpraAssets/Workshops/vr.jpeg"),
  baking: require("../../assets/images/vpraAssets/Workshops/baking.jpeg"),
};
